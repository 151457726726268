/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.w80 {
  width: 80%;
}

.w70 {
  width: 70%;
}

.flexColReverse {
  display: flex;
  flex-direction: column-reverse
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse
}

.none {
  display: none;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.flexAlign {
  @extend .flex;
  @extend .alignItemsCenter;
}

/************/

.container {
  padding: 2.5rem 1.25rem;

  .header {
    @extend .w100;
    @extend .flexAlignCol;

    .title {
      font-weight: 600;
    }

    .underline {
      height: 3px;
      width: 20%;
      margin-top: 0.5rem;
      background: var(--gold);
    }

    @media screen and (min-width: 768px) {
      .title {
        font-size: 2rem;
      }
    }
  }

  .content {
    padding: 2rem 0 3rem;
    @extend .flexAlignCol;

    .textContent {
      @extend .flexCol;
      gap: 1rem;

      .subtitle {
        margin-top: 1rem;
        text-align: center;
        font-family: var(--rale);
        color: var(--darkGreen);
      }

      .bold {
        font-weight: 700;
      }

      .barDiv {
        .boldText {
          font-weight: 700;
          color: var(--darkGreen);
        }

        .bar {
          @extend .w100;
          height: 10px;
          margin-top: 0.25rem;
          border-radius: 5px;
          background: rgba(128, 204, 40, 0.23);

          &::after {
            position: absolute;
            @extend .w70;
            background: var(--gold);
            height: 10px;
          }
        }
      }
    }

    .perksDiv {
      padding: 2rem 0;
      @extend .flexCol;
      gap: 1rem;

      .perk {
        @extend .flexAlignCol;
        @extend .justContentCenter;
        border-radius: 10px;
        gap: 0.5rem;
        padding: 1rem;
        box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.25);
        transition: all 0.3s ease;

        .div {
          text-align: center;

          .heading {
            color: var(--darkGreen);
            margin-bottom: 0.5rem;
            font-family: var(--rale);
            font-size: 1rem;
          }

          .text {
            font-size: 0.85rem;
          }
        }

        &:hover {
          background: linear-gradient(166.95deg, #80CC28 9.41%, #406614 93.81%);
          color: var(--white);
          .heading {
            color: var(--white);
          }
        }
      }
    }
  }
  

  @media screen and (min-width: 768px) {
    padding: 5% 10%;
  }

  @media screen and (min-width: 956px) {
    background-image: url('../../images/background/chooseBg.png');
    background-repeat: no-repeat;
    background-position: center left;
    padding: 5% 10%;
    .content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: flex-start;
      gap: 2rem;

      .textContent {
        .subtitle {
          text-align: left;
        }
      }

      .perksDiv {

        .perk {
          box-shadow: unset;
          border-radius: unset;
          flex-direction: row;
          padding: 0.5rem 0;
          gap: 1rem;
          justify-content: unset;
          align-items: flex-start;

          .div {
            text-align: left;
          }
  
          &:hover {
            background: unset;
            color: unset;
            .heading {
              color: unset;
            }
          }
        }
      }
    }
  }
}
