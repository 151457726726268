/* Declaring some common styles*/
.flexCol {
  display: flex;
  flex-direction: column;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

/************/
.stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: var(--white);

  .stat {
    @extend .flexAlignCol;
    padding: 1rem;
    background: linear-gradient(180deg, #406614 0%, #80CC28 100%);
    border: 1px solid var(--white);

    .icon {
      width: 40%;
    }

    .text {
      color: var(--darkGreen);
    }
  }

  @media screen and (min-width: 956px) {
    background-image: url('../../images/background/statsBg.png');
    background-size: cover;
    background-repeat: no-repeat;
    grid-template-columns: repeat(4, 1fr);
    padding: 3rem 10%;
    gap: 1rem;

    .stat {
      border-radius: 11px;
    }
  }
}
