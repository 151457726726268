/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColReverse {
  display: flex;
  flex-direction: column-reverse
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}


.container {
  @extend .flexCol;
  height: 110vh;
  background: url('../../images/background/mobileMain.png');
  background-size: cover;

  .wallet {
    @extend .flexCol;
    @extend .alignItemsCenter;
    gap: 1rem;
    min-height: 70vh;
    margin-top: 25vh;
    background: linear-gradient(169.44deg, #254105 7.86%, #406614 123.05%);
    color: white;
    padding: 0 1rem;

    .title {
      width: 100%;
      color: var(--light);
      padding: 0.5rem;

      .subtitle {
        @extend .flex;
        @extend .alignItemsCenter;
        gap: 0.35rem;

        .icon {
          font-size: 24px;
          cursor: pointer;
        }
      }
    }

    .walletContent {
      @extend .flexCol;
      gap: 0.3rem;
    }

    .walletSummary {
      @extend .flexCol;
      width: 100%;

      .walletList {

        .walletItem {
          @extend .flexCol;
          width: 100%;
          padding: 0.5rem;
          border-bottom: 1px solid var(--light);
          background: var(--darkGreen);
          border-radius: 10px;
          font-size: 13px;
          border: none;
          margin-bottom: 0.5rem;

          &:nth-of-type(odd) {
            background-color: var(--light);
            color: var(--darkGreen);
          }

          .walletItemContent {
            @extend .flex;
            @extend .justContentSpaceBtw;
            width: 100%;
          }
        }
      
      }
    }

    .btnDiv {
      @extend .flex;
      gap: 1rem;
      padding-bottom: 2rem;

      .btn {
        background: var(--light);
        color: var(--darkGreen);
        padding: 0.5rem 1rem;
        border-radius: 10px;
        border: none;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}