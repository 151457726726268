.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

/************/

.container {
  padding: 2.5rem 1.25rem;

  .header {
    @extend .w100;
    @extend .flexAlignCol;

    .title {
      font-weight: 600;
    }

    .underline {
      height: 3px;
      width: 20%;
      margin-top: 0.5rem;
      background: var(--gold);
    }

    .subtitle {
      margin-top: 1rem;
      text-align: center;
    }
  }

  .reviewCard {
    background: linear-gradient(180deg, #80CC28 0%, #406614 100%);
    color: white;
    margin: 0 auto;
    max-width: 400px;
    font-size: 0.7rem;
    padding: 2rem;
    margin-top: 2rem;

    .location {
      color: var(--darkGreen);
      font-size: 15px;;
    }

    .reviewText {
      font-size: 14px;
      text-align: center;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 5% 10%;

    .title {
      font-size: 2rem;
    }
    
    .subtitle {
      margin-top: 2rem;
      max-width: 500px;
    }

    .reviewCard {
      color: white;
      margin: 0 auto;
      padding: 1.25rem 1rem;
      margin-top: 2rem;
  
      .location {
        color: var(--darkGreen);
        font-size: 15px;;
      }
  
      .reviewText {
        font-size: 14px;
        text-align: center;
      }
    }
  }
}