/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.w80 {
  width: 80%;
}

.w70 {
  width: 70%;
}

.flexColReverse {
  display: flex;
  flex-direction: column-reverse
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse
}

.none {
  display: none;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.flexAlign {
  @extend .flex;
  @extend .alignItemsCenter;
}

.estate {
  background-image: url('../../images/background/estateBg.png');
}

.forex {
  background-image: url('../../images/background/forexBg.png');
}

.crypto {
  background-image: url('../../images/background/currencyBg.png');
}

.assetsDiv {
  padding: 2.5rem 0;
  @extend .relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;

  .header {
    @extend .w100;
    @extend .flexAlignCol;
    padding: 3rem 1.25rem 0;

    .title {
      font-weight: 600;
    }

    .underline {
      height: 3px;
      width: 20%;
      margin-top: 0.5rem;
      background: var(--gold);
    }

    .subtitle {
      text-align: center;
      padding: 1.5rem 0;
    }

    .flipper {
      @extend .flex;
      gap: 0.25rem;

      button {
        background: var(--gold);
        color: var(--darkGreen);
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        opacity: 0.5;

        &:hover {
          background: var(--light);
          color: var(--white);
          opacity: 0.7;
        }

        &.active {
          background: var(--light);
          color: var(--darkGreen);
          font-weight: 700;
          opacity: 1;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 5% 10%;
    .header {
      padding: 3rem 1.25rem 0;

      .title {
        font-size: 2rem;
      }

      .subtitle {
        font-size: 1.25rem;
      }
    }
    
  }
}