.notification {
  position: fixed;
  top: 80vh;
  width: 55%;
  left: 40%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #333;
  border: 2px solid rgb(161, 223, 90);
  color: rgb(161, 223, 90);
  border-radius: 5px;
  z-index: 900000000000000;
  transition: opacity 0.3s ease;
  opacity: 0;

  @media screen and (min-width: 768px){
    width: 300px;
    left: 21%;
    top: 90vh;
  }
}

.visible {
  opacity: 1;
}
