/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.w80 {
  width: 80%;
}

.w70 {
  width: 70%;
}

.flexColReverse {
  display: flex;
  flex-direction: column-reverse
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse
}

.none {
  display: none;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.flexAlign {
  @extend .flex;
  @extend .alignItemsCenter;
}

/************/

.proContainer {
  @extend .flexAlignCol;
  gap: 1.5rem;
  color: var(--black);
  padding: 3rem 0;

  .plan {
    background: var(--white);
    width: 80%;
    text-align: center;
    border-radius: 0 0 20px 20px;

    .header {
      background: var(--light);
      padding: 1rem;
      text-align: center;
      @extend .flexAlignCol;
      gap: 0.15rem;
      font-family: var(--rale);

      .heading {
        font-size: 1.25rem;
        font-weight: 700;
        text-transform: uppercase;
      }

      .text:first-of-type {
        font-size: 2.7rem;
        font-weight: 700;
        color: var(--white);
      }
    }

    .headerR {
      @extend .header;
      background: var(--gold);

      .text:first-of-type {
        color: var(--darkGreen);
      }
    }

    .subtitle {
      padding: 1rem;
      font-family: var(--rale);
      font-size: 1.15rem;
      font-weight: 700;
      color: var(--darkGreen);
    }

    .text {
      padding-top: 0.5rem;
      font-size: 1.15rem;
    }

    .btn {
      padding: 0.5rem 1rem;
      margin: 1rem 0;
      width:60%;
      border: 1px solid var(--light);
      text-transform: uppercase;
      color: var(--darkGreen);
      font-weight: 600;
      border-radius: 10px;
      background: transparent;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: linear-gradient(90deg, #80CC28 0%, #406614 100%);
        color: var(--white);
        border: none;
        box-shadow: none;
      }
    
      &:active {
        transform: scale(0.9);
      }
    }

    .modal {
      .modalContent {
        .form {
          @extend .flexAlignCol;
          gap: 0.5rem;

          .input {
            width: 60%;
            padding: 0.5rem;
          }
        }
      }
    }
  }

  .seeMore {
    padding: 0.5rem 1rem;
    width:50%;
    margin-top: 2rem;
    border: 1px solid var(--white);
    color: var(--white);
    border-radius: 10px;
    background: transparent;
    text-align: center;
    cursor: pointer;
    transition: all 0.1s ease;

    &:hover {
      background: linear-gradient(90deg, #80CC28 0%, #406614 100%);
      color: var(--white);
      border: none;
      box-shadow: none;
    }
  
    &:active {
      transform: scale(0.9);
    }
  }

  @media screen and (min-width: 768px){
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    .plan {
      width: 100%;

      .text {
        font-size: 1rem;
      }
    }

    .seeMore {
      max-width: 40%;
      grid-column: 1 / span 2;
      justify-self: center;
    }
  }

  @media screen and (min-width: 956px){
    grid-template-columns: repeat(3, 1fr);

    .seeMore {
      grid-column: 1 / span 3;
    }
  }
}