/* Declaring some common styles*/
.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

/************/

.faqContainer {
  padding: 2.5rem 1.25rem;

  .header {
    @extend .w100;
    @extend .flexAlignCol;

    .title {
      font-weight: 600;
    }

    .underline {
      height: 3px;
      width: 20%;
      margin-top: 0.5rem;
      background: var(--gold);
    }
  }

  .faqContent {
    padding: 2rem 0 3rem;
    @extend .flexAlignCol;
    gap: 1rem;

    .queries {
      @extend .flexCol;
      gap: 1rem;

      .flip {
        font-size: 1.15rem;
      }

      .faqAnswer {
        background: rgba(227, 238, 214, 1);
        padding: 0.5rem;
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 5% 10%;

    .header {
      .title {
        font-size: 2rem;
      }
    }

    .faqContent {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-items: space-between;
      gap: 2rem;

      .img {
        order: 2;
        width: 100%;
      }
    }
  }
}