/* Declaring some common styles*/
.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

/************/

.container {

  .steps {
    padding: 2.5rem 1.25rem;

    .header {
      @extend .w100;
      @extend .flexAlignCol;

      .title {
        font-weight: 600;
      }
      .subtitle {
        margin-top: 1rem;
        text-align: center;
      }
    }

    .reviewCard {
      background: linear-gradient(180deg, #80CC28 0%, #406614 100%);
      color: white;
      padding: 1.25rem 1rem;
      margin-top: 2rem;

      .location {
        color: var(--darkGreen);
        font-size: 15px;;
      }

      .reviewText {
        font-size: 14px;
        text-align: center;
      }
    }

    @media screen and (min-width: 768px) {
      padding: 5% 10%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      .header {
        .subtitle {
          max-width: 300px;
        }

        .pic {
          width: 70%;
        }
      }

      .reviewDiv {
        width: 30%;
        .carousel {
          align-items: center;
          .reviewCard {
            max-width: 400px;
          }
        }
      }
    }
  }
}
