/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}
/************/

.container {
  @extend .w100;
  @extend .relative;
  padding: 3rem 1.25rem;

  .img {
    width: 90%;
    height: 50vh;
  }

  .textContent {
    @extend .absolute;
    top: 3rem;
    @extend .w90;
    padding: 0 1rem;
    height: 50vh;
    color: white;
    background: rgba(37, 65, 5, 0.73);
    text-align: center;

    .subtitle {
      font-family: var(--rale);
      padding-top: 3rem;
      font-weight: 700;
      font-size: 1.35rem;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 300%;
      background-image: -webkit-linear-gradient(180deg, #FFFFFF 0%, #406614 100%);
      -webkit-animation: motion 10s infinite alternate;
      animation: motion 10s infinite alternate;
    }

    .text {
      padding: 1rem 0;
    }

    @keyframes motion {
      0% {
        background-position: left;
      }
      50% {
        background-position: right bottom;
      }
      75% {
        background-position: left bottom;
      }
      100% {
        background-position: right;
      }
    }
  }

  @media screen and (min-width: 768px) {
    display: flex;
    padding: 10%;

    .img {
      max-width: 50%;
      height: unset;
    }

    .textContent {
      position: static;
      height: unset;
      text-align: left;
      color: unset;
      background: none;
      font-size: 0.9rem;

      .subtitle {
        font-size: 1.5rem;
        background-size: 200%;
        padding-top: 0;
        line-height: 1.2;
      }

      .text {
        padding: 0.5rem 0;
      }

      .action {
        button:hover {
          background: var(--green);
          color: white;
        }
      }
    }
  }
}