/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.w80 {
  width: 80%;
}

.w70 {
  width: 70%;
}

.flexColReverse {
  display: flex;
  flex-direction: column-reverse
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse
}

.none {
  display: none;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.flexAlign {
  @extend .flex;
  @extend .alignItemsCenter;
}

.container {

  .steps {
    @extend .flexAlignCol;

    .title {
      font-weight: 700;
      color: var(--darkGreen);
      font-size: 1.5rem;
    }

    padding: 2.5rem 1.25rem;

    .list {
      @extend .w100;
      @extend .flexAlignCol;
      gap: 3.5rem;
      padding: 3rem 0;

      .step {
        @extend .list;
        @extend .relative;
        gap: 1rem;
        text-align: center;
        box-shadow: 0px 1px 4px 3px rgba(0, 0, 0, 0.25);
        padding: 1.5rem 1.25rem;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
        height: 198px;

        .index {
          @extend .absolute;
          top: -1.8rem;
          width: 3rem;
          height: 3rem;
          background: var(--light);
          color: var(--darkGreen);
          border-radius: 50%;
          @extend .flexAlignCol;
          @extend .justContentCenter;
          transition: all 0.3s ease-in-out;
        }

        .stepTitle {
          font-weight: 700;
          color: var(--darkGreen);
          font-size: 1.2rem;
        }

        .stepText {
          font-size: 0.8rem;
        }

        &:hover {
          background: linear-gradient(166.95deg, #80CC28 9.41%, #406614 93.81%);
          color: var(--white);
          
          .index {
            background: var(--gold);
            color: var(--white);
          }
        }
      }
    }

    @media screen and (min-width: 768px) {
      padding: 5% 10%;
      margin-top: 2rem;

      .list {
        .step {
          // flex-direction: row;
          gap: 1rem;
          text-align: left;
          align-items: flex-start;
          box-shadow: unset;
          padding: 0;
          max-width: 70%;
          border-radius: unset;
          height: unset;

          &:hover {
            background: unset;
            color: unset;
          }

          &:before {
            position: absolute;
            content: url('../images/icons/list.svg');
            color: var(--darkGreen);
            transition: all 0.3s ease-in-out;
            top: -0.7rem;
            left: -3.4rem;
          }

          .index {
            display: none;
          }
        }
      }
    }
  }
}