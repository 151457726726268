.sponsorsSection {
  background-image: url('../../images/background/sponsor.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1.25rem;

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4rem;

    li {

      img {
        // width: 30%;
        align-self: center;
        height: 20%;
        object-fit: contain;
      }
    }

    .logoX {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .react-multi-carousel-item {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // .logoX {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;

  //   .img {
  //     width: 70%;
  //     align-self: center;
  //     height: 50%;
  //     object-fit: contain;
  //   }
  // }
}