/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColReverse {
  display: flex;
  flex-direction: column-reverse
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

/************/

.container {
  @extend .flexCol;
  // @extend .justContentCenter;
  position: absolute;
  margin-top: 36px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 110vh;
  width: 100vw;
  background: url('../../images/background/mobileMain.png');
  background-size: cover;

  .header {
    z-index: 1000;
    color: white;
    padding: 0 2rem;
    font-family: var(--rale);
    text-align: center;
    margin-top: 10rem;
  }

  .form {
    z-index: 1000;
    @extend .flexCol;
    gap: 0.25rem;
    padding: 2rem;

    .input {
      border: 0.68px solid rgba(255, 255, 255, 1);
      height: 48px;
      width: 100%;
      background: transparent;
      border-radius: 10px;
      padding: 0 1rem;
      color: var(--light);
      caret-color: var(--light);
      
      &::placeholder {
        color: var(--white);
      }

      &:focus {
        border: 0.68px solid var(--gold);
        outline: none;
      }
    }

    .btn {
      background: var(--light);
      color: var(--darkGreen);
      border: none;
      margin-top: 0.25rem;
      height: 48px;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.3s;

      .spinner {
        transition: 0.3s;
      }

      &:active .spinner {
        transform: rotate(360);
      }

      &:hover {
        background: transparent;
        color: var(--light);
        border: 0.68px solid var(--light);
      }

      &:active {
        transform: scale(0.95);
        background: var(--light);
        color: var(--white);
      }
    }

    .link {
      color: var(--white);
      align-self: center;
      margin-top: 0.5rem;

      a {
        color: var(--light);
        text-decoration: none;
        transition: 0.3s;

        &:hover {
          color: var(--white);
        }
      }
    }

    .label {
      color: var(--white);
      font-size: 1rem;
      font-weight: 700;
      margin-right: 1rem;
    }

    @media screen and (min-width: 768px) {
      width: 40%;
      margin: 0 auto;
    }
  }

  .modal {
    position: absolute;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(6px);
    z-index: 50000000000;

    .miniForm {
      @extend .flexCol;
      @extend .justContentCenter;
      position: absolute;
      left: 50%;
      top: 50%;
      gap: 1rem;
      transform: translate(-50%, -50%);
      height: 50vh;
      padding: 1rem 1.25rem;
      width: 80vw;
      background-color: var(--light);
  
      .input {
        border: 0.68px solid rgba(255, 255, 255, 1);
        height: 48px;
        width: 100%;
        background: transparent;
        border-radius: 10px;
        padding: 0 1rem;
        color: var(--darkGreen);
        caret-color: var(--light);
        
        &::placeholder {
          color: var(--white);
        }
  
        &:focus {
          border: 0.68px solid var(--darkGreen);
          outline: none;
        }
      }
  
      .label {
        color: var(--white);
        font-size: 1rem;
        font-weight: 700;
        margin-right: 1rem;
      }
  
      .btn {
        background: var(--darkGreen);
        color: var(--light);
        border: none;
        margin-top: 0.25rem;
        height: 48px;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.3s;
  
        .spinner {
          transition: 0.3s;
        }
  
        &:active .spinner {
          transform: rotate(360);
        }
  
        &:hover {
          background: transparent;
          color: var(--darkGreen);
          border: 0.68px solid var(--white);
        }
  
        &:active {
          transform: scale(0.95);
          background: var(--light);
          color: var(--white);
        }
      }
    }
  }
}