@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;600;700;800;900&family=Raleway:wght@400;700;900&display=swap');

:root {
  /* Colour */
  --black: #000;
  --white: #fff;
  --green: rgba(64, 102, 20, 1);
  --darkGreen: rgba(37, 65, 5, 1);
  --light: rgba(128, 204, 40, 1);
  --gold: rgba(255, 205, 46, 1);

  /* Fonts & Sizes */
  --rale: 'Raleway', system-ui, cursive;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

a,
button {
  cursor: pointer;
  font-family: var(--prompt);
  font-size: 1rem;
}

img {
  max-width: 100%;
  height: auto;
}

menu {
  list-style-type: none;
}

body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
  transition: 0.3s;
  font-family:
    'Satoshi',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* #tradingview-widget-container {
  display: none !important;
  position: fixed;
}

.tradingview-widget-container__widget {
  display: none;
  position: absolute;
} */
