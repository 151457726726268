/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColReverse {
  display: flex;
  flex-direction: column-reverse
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}


.container {
  @extend .flexCol;
  height: 110vh;
  background: url('../../images/background/mobileMain.png');
  background-size: cover;

  .wallet {
    @extend .flexCol;
    @extend .alignItemsCenter;
    gap: 1rem;
    min-height: 70vh;
    margin-top: 25vh;
    background: linear-gradient(169.44deg, #254105 7.86%, #406614 123.05%);
    color: white;
    padding: 0 1rem;

    .title {
      width: 100%;
      color: var(--light);
      padding: 0.5rem;

      .subtitle {
        @extend .flex;
        @extend .alignItemsCenter;
        gap: 0.35rem;

        .icon {
          font-size: 24px;
          cursor: pointer;
        }
      }
    }

    .walletContent {
      @extend .flexCol;
      align-self: flex-start;
      gap: 0.3rem;

      .walletTitle {
        font-size: 14px;
      }

      .walletAmount {
        font-size: 20px;
        font-weight: 700;
      }
    }

    .walletSummary {
      @extend .flexCol;
      @extend .alignItemsCenter;
      width: 100%;

      .walletTable {
        background-color: var(--light);
        margin-top: 1rem;
        width: 80%;
        border-radius: 10px;
        padding: 1rem;

        @media screen and (min-width: 956px) {
          width: 50%;
        }

        thead {
          color: var(--darkGreen);;
          tr {
            border-bottom: 1px solid var(--darkGreen);
            margin-bottom: 0.5rem;
            @extend .flex;
            @extend .justContentSpaceBtw;
          }
        }

        tbody {
          text-align: left;

          tr {
            @extend .flex;
            @extend .justContentSpaceBtw;
          }
        }
      }
    }

    .btnDiv {
      @extend .flexCol;
      gap: 1rem;
      padding-bottom: 2rem;

      @media screen and (min-width: 956px) {
        display: flex;
        flex-direction: row;
        margin-top: 2rem;
      }
    }
  }

  .popWallet {
    @extend .absolute;
    translate: -50%, -50%;
    left: 2%;
    top: 25%;
    width: 95%;
    padding: 2rem 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin: 0 auto;
    background-color: var(--gold);

    .exitMenu {
      @extend .absolute;
      left: 90%;
      top: 3%;
      font-size: 1.5rem;
      cursor: pointer;
    }

    .popWalletContent {
      @extend .flexCol;
      @extend .alignItemsCenter;
      gap: 0.3rem;

      .popWalletTitle {
        font-size: 14px;
        color: var(--darkGreen);
      }
    }
  }
}