/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.w80 {
  width: 80%;
}

.w70 {
  width: 70%;
}

.flexColReverse {
  display: flex;
  flex-direction: column-reverse
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse
}

.none {
  display: none;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.flexAlign {
  @extend .flex;
  @extend .alignItemsCenter;
}

/************/

.container {
  background-image: url('../../images/background/offerBg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3rem 1.25rem;

  .header {
    @extend .w100;
    @extend .flexAlignCol;

    .title {
      font-weight: 600;
      color: var(--white);
    }

    .underline {
      height: 3px;
      width: 20%;
      margin-top: 0.5rem;
      background: var(--light);
    }
  }

  .content {
    padding: 3rem 0 3rem;
    @extend .flexAlignCol;
    gap: 1rem;

    .linker {
      @extend .flexAlignCol;
      gap: 1rem;
      @extend .w80;
      max-width: 250px;
      background: var(--white);
      border-radius: 0 0 10px 10px;
      transition: all 0.3s ease-in;

      .icon {
        width: 100%;
      }

      .div{
        padding: 0 1rem 1.5rem;
        text-align: center;

        .heading {
          color: var(--darkGreen);
          font-family: var(--rale);
          font-size: 1.15rem;
          margin-bottom: 0.5rem;
          font-weight: 700;
        }

        .text {
          font-size: 15px;
          color: black;
        }
      }

      &:hover {
        background: var(--darkGreen);
        color: var(--white);

        .heading {
          color: var(--light);
        }

        .text {
          color: var(--white);
        }
      }

      &:active {
        transform: scale(0.5);
      }
    }
  }

  @media screen and (min-width: 768px){
    padding: 5% 10%;
    
    .title {
      font-size: 2rem;
    }

    .content {
      flex-direction: row;
      justify-content: center;
      gap: 1rem;

      .linker {
        height: 380px;

        .div {
          .text {
            font-size: 14px;
          }
        }
      }
    }
  }
}
