/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/************/

.container {
  padding: 2.5rem 1.25rem;

  .header {
    @extend .w100;
    @extend .flexAlignCol;

    .title {
      font-weight: 600;
    }

    .underline {
      height: 3px;
      width: 20%;
      margin-top: 0.5rem;
      background: var(--gold);
    }
  }

  .content {
    padding: 2rem 0 3rem;
    @extend .flexAlignCol;

    .textContent {
      @extend .flexAlignCol;
      gap: 1rem;

      .subtitle {
        margin-top: 1rem;
        color: var(--darkGreen);
        font-family: var(--rale);
        font-weight: 700;
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 5% 10%;
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
      gap: 2rem;
      margin-top: 2rem;

      .aboutImg {
        width: 100%;
      }

      .textContent {
        align-items: flex-start;
        order: -1;

        .subtitle {
          margin-top: 0;
        }
      }
    }

    .title {
      font-size: 2rem;
    }
  }
}