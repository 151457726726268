/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

/************/

.container {
  padding: 2.5rem 1.25rem;

  @media screen and (min-width: 768px) {
    padding: 5% 10%;

    .title {
      font-size: 2rem;
    }
  }

  @media screen and (min-width: 956px) {
    background-image: url('../../images/background/sideBg.png');
    background-repeat: no-repeat;
    background-position: top left;
  }

  .header {
    @extend .w100;
    @extend .flexAlignCol;

    .title {
      font-weight: 600;
      text-align: center;
    }

    .underline {
      height: 3px;
      width: 20%;
      margin-top: 0.5rem;
      background: var(--gold);
    }
  }

  .content {
    padding: 2rem 0 3rem;
    @extend .w100;
    @extend .flexAlignCol;

    .tableDiv {
      @extend .w100;
      overflow-x: auto;
      margin-top: 2rem;

      h2 {
        font-weight: 600;
        font-size: 1.25rem;
        text-align: center;
        background: var(--darkGreen);
        color: var(--white);
        padding: 0.5rem;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        text-align: center;

        th {
          background: rgba(227, 238, 214, 1);
          color: var(--darkGreen);
          padding: 0.5rem;
          text-align: left;
        }

        td {
          padding: 0.5rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.19);
        }

        tbody {
          text-align: left;
        }
      }
    }

    @media screen and (min-width:956px) {
      flex-direction: row;
      gap: 2rem;
    }
  }
}