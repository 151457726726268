/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.w80 {
  width: 80%;
}

.w70 {
  width: 70%;
}

.flexColReverse {
  display: flex;
  flex-direction: column-reverse
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse
}

.none {
  display: none;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

/************/

.header {
  @extend .w100;
  position: fixed;
  z-index: 100000;

  @media screen and (min-width: 1024px) {
    position: absolute;
    backdrop-filter: blur(6px);
  }

  .topNav {
    @extend .flex;
    @extend .justContentSpaceBtw;
    @extend .alignItemsCenter;
    height: 36px;
    padding: 0 1rem;
    background: linear-gradient(90.03deg, #80CC28 0.02%, #406614 99.98%);
    
    .firstSec {
      @extend .flex;
      @extend .alignItemsCenter;
      color: var(--darkGreen);
      font-size: 14px;
      cursor: pointer;

      .mail {
        @extend .none;
      }

      .language {
        padding: 0.25rem 1rem;
        background: transparent;
        border-color: var(--white);
        border-radius: 5px;
      }

      .secSec {
        padding-left: 0.5rem;
        @extend .flex;
        gap: 0.25rem;
        align-items: center;
        color: var(--white);
        font-size: 14px;
        cursor: pointer;

        .icon {
          color: var(--darkGreen);
          font-weight: bold;
        }
      }

      @media screen and (min-width: 768px) {
        gap: 1.5rem;
        
        .mail {
          display: flex;
          align-items: center;
          gap: 0.25rem;

          a {
            color: var(--white);
            font-size: 14px;
          }
        }
      }
    }

    .secondSec {
      @extend .flex;
      @extend .alignItemsCenter;
      @extend .justContentCenter;
      // position: relative;

      .social {
        @extend .flex;
        gap: 1.25rem;
        color: var(--darkGreen);
        height: inherit;

        cursor: pointer;

        .socialLink {
          color: var(--white);
          display: block;
          margin-top: 6px;
        }
      }

      .auth {
        position: fixed;
        top: 2.2rem;
        left: 65%;
        width: 35%;
        z-index: 50000000;
        background-color: var(--white);

        .authList {
          @extend .flexCol;
          padding: 1rem 0;
          gap: 0.5rem;
          color: var(--darkGreen);

          .authItem {
            transition: 0.3s;

            &:hover {
              background-color: var(--gold);
            }

            &:active {
              background-color: var(--gold);
            }
          }
          .authLink {
            @extend .flex;
            padding: 0 0.5rem;
            @extend .alignItemsCenter;
            gap: 0.5rem;
            border: none;
            background: transparent;
            color: var(--darkGreen);
          }
        }

        @media screen and (min-width: 768px) {
          top: 3.1rem;
          left: 80%;
          width: 20%;
        }
      }
    }

    @media screen and (min-width: 768px) {
      padding: 1.5rem 5%;
    }
  }

  .nav {
    @extend .w100;
    @extend .flex;
    @extend .relative;
    @extend .justContentSpaceBtw;
    @extend .alignItemsCenter;
    padding: 1rem;

    @media screen and (min-width: 768px) {
      padding: 1rem 5%;
    }

    .scrolled {
      background-color: var(--darkGreen);
    }

    .logo {
      .logoImg {
        max-width: 70%;
  
        @media screen and (min-width: 768px) {
          max-width: 80%;
          display: block;
        }
      }
    }

    .mobileMenuIcon {
      font-size: 2.5rem;
      color: var(--light);
      cursor: pointer;
      transition: all 0.6s ease-in-out;

      &:active {
        color: var(--darkGreen);
        transform: scale(0.9);
      }

      @media screen and (min-width: 1024px) {
        display: none;
      }
    }

    .exitMenu {
      @extend .mobileMenuIcon;
      z-index: 500000;
    }

    .hide {
      @extend .none;

      @media screen and (min-width: 1024px) {
        display: flex;
      }
    }

    .navList {
      position: fixed;
      list-style-type: none;
      padding-top: 3rem;
      height: 70vh;
      top: 2.2rem;
      left: 0;
      width: 100vw;
      background: #406614;

      .navItem {
        .navLink {
          color: var(--white);
          padding: 1rem;
          display: block;
          width: 80%;
          background: transparent;
          border: none;
          border-bottom: 1px solid var(--darkGreen);
          cursor: pointer;
          text-align: left;
          transition: all 0.3s ease;

          &:hover {
            background: var(--darkGreen);
          }
        }

        select {
          font-size: 1rem;

          &:focus {
            border-width: 0;
            outline: none;
          }
        }
      }

      @media screen and (min-width: 1024px) {
        display: flex;
        position: unset;
        padding-top: 0;
        height: unset;
        width: unset;
        align-items: center;
        background: transparent;
        gap: 0.5rem;

        .navItem {
          select {
            font-size: 1rem;
            width: fit-content;
          }

          .navLink {
            color: var(--light);
            padding: 0.5rem;
            display: flex;
            width: unset;
            background: transparent;
            border: none;
            border-bottom: none;
            text-align: unset;
            transition: all 0.3s ease;
  
            &:hover {
              color: var(--gold);
              background: unset;
            }

            & option {
              background-color: var(--light);
              color: white;
              margin-bottom: 3px;
            }
          }

          .navLinker {
            width: 100%;
            color: var(--white);
            background: linear-gradient(90deg, #80CC28 0%, #406614 100%);
            box-shadow: 1px 3px 3px 0px rgba(231, 188, 35, 0.56);
            border-radius: 11px;
            transition: all 0.3s ease;

            &:hover {
              background: linear-gradient(166.95deg, #80CC28 9.41%, #406614 93.81%);
              color: var(--darkGreen);
            }
          }
        }
      }
    }
  }
}