.container {
  width: 100%;

  .video {
    max-width: 100%;
  }

  @media screen and (min-width: 768px) {
    padding: 5% 10%;

    .video {
      max-width: 100%;
    }  
  }
}