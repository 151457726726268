.scroll {
  position: fixed;
  cursor: pointer;
  top: 90vh;
  font-size: 2.5rem;
  left: 88%;
  color: var(--light);
  transition: all 0.3s ease;
  z-index: 800000000;

  &:hover {
    color: var(--darkGreen);
  }

  @media screen and (min-width: 768px) {
    font-size: 3rem;
  }
}
