/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColReverse {
  display: flex;
  flex-direction: column-reverse
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

/************/

.container {
  @extend .flexCol;
  @extend .justContentCenter;
  height: 100vh;
  background: url('../images/background/mobileMain.png');
  background-size: cover;

  .lift {
    position: absolute;
    width: 100%;
    top: 0;
  }

  .title {
    z-index: 1000;
    color: white;
    padding: 0 2rem;
    font-family: var(--rale);
    text-align: center;
    margin-top: 10rem;

    .subtitle {
      font-size: 1.5rem;
      font-family: var(--prompt);
      color: var(--light);
      font-weight: 600;
    }

    .desc {
      max-width: 300px;
      @extend .auto;
    }
  }

  .form {
    z-index: 1000;
    @extend .flexCol;
    gap: 0.25rem;
    padding: 2rem;

    .input {
      border: 0.68px solid rgba(255, 255, 255, 1);
      height: 48px;
      background: transparent;
      border-radius: 10px;
      padding: 0 1rem;
      color: var(--light);
      caret-color: var(--light);
      
      &::placeholder {
        color: var(--white);
      }

      &:focus {
        border: 0.68px solid var(--gold);
        outline: none;
      }
    }

    .btn {
      background: var(--light);
      color: var(--darkGreen);
      border: none;
      margin-top: 0.25rem;
      height: 48px;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.3s;

      .spinner {
        transition: 0.3s;
      }

      &:active .spinner {
        transform: rotate(360);
      }

      &:hover {
        background: transparent;
        color: var(--light);
        border: 0.68px solid var(--light);
      }

      &:active {
        transform: scale(0.95);
        background: var(--light);
        color: var(--white);
      }
    }

    .link {
      color: var(--white);
      align-self: center;
      margin-top: 0.5rem;

      a {
        color: var(--light);
        text-decoration: none;
        transition: 0.3s;

        &:hover {
          color: var(--white);
        }
      }
    }

    @media screen and (min-width: 768px) {
      width: 40%;
      margin: 0 auto;
    }
  }

  .success {
    position: absolute;
    @extend .flexCol;
    @extend .justContentCenter;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    z-index: 60000;
    color: var(--darkGreen);
    text-align: center;
    gap: 1rem;

    a {
      color: var(--darkGreen);
      text-decoration: underline;

      padding-top: 2rem;
      font-size: 0.85rem;
      transition: 0.3s;

      &:hover {
        color: var(--darkGreen);
      }
    }
  }
}