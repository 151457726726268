/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.flexAlign {
  @extend .flex;
  @extend .alignItemsCenter;
}

/************/

.container {
  padding: 2.5rem 1.25rem;

  .header {
    @extend .w100;
    @extend .flexAlignCol;

    .title {
      font-weight: 600;
    }

    .underline {
      height: 3px;
      width: 20%;
      margin-top: 0.5rem;
      background: var(--gold);
    }
  }

  .content {
    margin-top: 1rem;
    @extend .relative;
    padding: 2rem 0.5rem 3rem;
    @extend .flex;
    background: linear-gradient(180deg, rgba(128, 204, 40, 0.43) 0%, rgba(64, 102, 20, 0.43) 100%);
    

    .textContent {
      @extend .w100;
      @extend .flexCol;
      padding-right: 1rem;
      gap: 0.5rem;

      .text {
        @extend .flexAlign;
        gap: 0.25rem;

        .list {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          background-color: var(--light);
          color: var(--darkGreen);
          font-family: var(--rale);
          @extend .flexAlignCol;
          @extend .justContentCenter;
          font-weight: 700;
        }
      }
    }

    .img {
      width: 30%;
      position: absolute;
      left: 68%;
      top: 40%;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 5% 10%;

    .header {
      .title {
        font-size: 2rem;
      }
    }

    .content {
      background: linear-gradient(90deg, #FFCD2E 0%, #997B1C 100%);
      padding: 3rem;
      .textContent {

        .text {
          .list {
            background-color: var(--white);
          }
        }
        
      }

      .img {
        top: 10%;
        max-width: 100%;
      }
    }
    
  }
}