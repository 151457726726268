.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/************/

.container {
  padding: 3rem 1.25rem;
  background: linear-gradient(116.72deg, #80CC28 21.39%, #406614 102.82%);
  @extend .flexAlignCol;
  gap: 2rem;

  .ask {
    @extend .flexAlignCol;
    gap: 1rem;
    text-align: center;
    @extend .w100;
    color: var(--white);

    button {
      padding: 0.5rem;
      border: 2px solid var(--light);
      color: var(--darkGreen);
      width: 76%;
      font-weight: 700;
      border-radius: 10px;
      background: transparent;
      cursor: pointer;
      transition: all 0.1s ease;

      &:hover {
        background: linear-gradient(90deg, #80CC28 0%, #406614 100%);
        color: var(--white);
        border: none;
        box-shadow: none;
      }
    
      &:active {
        transform: scale(0.9);
      }
    }
  }

  .border {
    height: 1px;
    background-color:  var(--white);
    @extend .w90;
  }

  @media screen and (min-width: 768px){
    padding: 5% 10%;
    flex-direction: row;

    .border {
      height: 15vh;
      background-color: var(--white);
      width: 1px;
    }
  }
}