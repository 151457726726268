.primaryBtn {
  background: linear-gradient(90deg, #80CC28 0%, #406614 100%);
  padding: 1rem;
  border: none;
  border-radius: 10px;
  font-weight: 700;
  color: var(--white);
  cursor: pointer;
  box-shadow: 0.7443204522132874px 2.232961416244507px 2.232961416244507px 0px rgba(231, 188, 35, 0.56);
  transition: all 0.1s ease;

  &:hover {
    background: transparent;
    color: var(--white);
    border: 2px solid var(--light);
    box-shadow: none;
  }

  &:active {
    transform: scale(0.9);
  }
}