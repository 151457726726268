.topSection {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;                                                                                  

  @media screen and (min-width: 768px) {
    height: 45vh;
  }
}

.topImage {
  position: relative;
  width: 120%;
  text-align: center;
  object-fit: cover;
  height: 270px;

  @media screen and (min-width: 768px) {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 45vh;
    top: 27vh;
    background-color: #fff;
    object-fit: cover;
    z-index: 1;
  }
}

.topHeader {
  position: absolute;
  line-height: 1.1;
  top: 23%;
  font-size: 38px;
  z-index: 3;
  color: var(--white);
  font-weight: 900;
  font-family: var(--rale);
  transform: translate(-50%, -50%);

  @media screen and (min-width: 768px) {
    font-size: 3rem;
    top: 24%;
    padding: 1rem;
  }
}

.topText {
  position: absolute;
  font-size: 13px;
  top: 30%;
  z-index: 3;
  color: var(--white);
  font-family: var(--rale);
  max-width: 350px;
  transform: translate(-50%, -50%);

  @media screen and (min-width: 768px) {
    font-size: 1rem;
    line-height: 1.5;
    top: 35%;;
  }
}