/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.w80 {
  width: 80%;
}

.w70 {
  width: 70%;
}

.flexColReverse {
  display: flex;
  flex-direction: column-reverse
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse
}

.none {
  display: none;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

/************/

.main {
  @extend .w100;
  // background-image: url('../images/background/mainBg.png');
  background-size: cover;
  background-color: var(--green);
  background-repeat: no-repeat;
  height: 100%;
  padding: 13rem 1.25rem;
  @extend .relative;
  text-align: center;
  @extend .flexCol;
  @extend .alignItemsCenter;
  z-index: 1;

  .videoBg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
    object-fit: cover;
    z-index: -1;
  }

  .title {
    font-family: var(--rale);
    font-size: 2rem;
    color: var(--white);
    font-weight: 900;
    transition: all 0.3s ease-in-out;
    animation: infinite scaler 5s ease alternate;
    margin-bottom: 2rem;

    @keyframes scaler {
      from {
        transform: scale(0.9);
      }

      to {
        transform: scale(1.1);
      }
      
    }

    .tint {
      color: var(--light);
      transition: all 0.3s ease-in-out;
      animation: infinite scale 3s ease-in-out alternate;
    }

    @keyframes scale {
      0% {
        opacity: 0.3;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
      
    }

    @media screen and (min-width: 768px) {
      font-size: 3rem;
      max-width: 80%;

      @keyframes scaler {
        from {
          transform: scale(0.9);
        }
        to {
          transform: scale(1);
        } 
      }

      .tint {
        text-transform: uppercase;
      }
    }
  }

  .text {
    max-width: 90%;
    margin-bottom: 1rem;
    color: var(--white);
    line-height: 1.5rem;

    .tint {
      color: var(--light);
    }

    @media screen and (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .btnDiv {
    @extend .flexCol;
    gap: 1rem;
    width: 212px;
    margin-top: 1rem;

    .unlock {
      padding: 1rem;
      border: 2px solid var(--light);
      color: var(--white);
      font-weight: 700;
      border-radius: 10px;
      background: transparent;
      cursor: pointer;
      transition: all 0.1s ease;

      &:hover {
        background: linear-gradient(90deg, #80CC28 0%, #406614 100%);
        color: var(--white);
        border: none;
        box-shadow: none;
      }
    
      &:active {
        transform: scale(0.9);
      }
    }

    .videoContainer {
      position: absolute;
      backdrop-filter: blur(5px);
      top: 20%;
      left: 50%;
      width: 100%;
      height: 50vh;
      transform: translate(-50%, 50%);

      .exitMenu {
        // z-index: 500000;
        font-size: 2.5rem;
        color: var(--white);
        cursor: pointer;
        transition: all 0.6s ease-in-out;
  
        &:active {
          color: var(--darkGreen);
          transform: scale(0.9);
        }
      }

      .video {
        @extend .w90;
      }
    }


    @media screen and (min-width: 768px) {
      flex-direction: row;
      width: 100%;
      margin-top: 3rem;

      .videoContainer {
        top: -5%;
        left: 50%;
        width: 80%;
        height: 50vh;
        display: flex;
        backdrop-filter: unset;

  
        .exitMenu {
          position: absolute;
          font-size: 2.5rem;
          top: -3rem;
          left: 50%;
        }
  
        .video {
          width: 100%;
        }
      }
    }
  }

  .tez {
    @extend .none;
  }

  @media screen and (min-width: 768px) {
    text-align: left;
    align-items: flex-start;
    padding: 25% 10%;

    .tez {
      display: flex;
      width: 15%;
      position: absolute;
      left: 80%;
      top: 50%;
      animation: infinite rotator 10s ease-in-out alternate;

      @keyframes rotator {
        from {
          left: 70%;
          transform: scale(0.76);
        }
        to {
          left: 80%;
          transform: scale(1.1);
        } 
      }
    }
  }
}